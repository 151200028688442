<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="block">
            <div class="inline-block col-6 md:col-6">
              <label for="status">Status</label>
              <Dropdown
                id="status"
                v-model="v$.consignacao.statusConsignacaoDto.$model"
                data-key="id"
                :class="{
                  'p-invalid':
                    submitted && v$.consignacao.statusConsignacaoDto.$invalid,
                }"
                :options="listaStatusConsignacao"
                placeholder="Selecione um tipo">
                <template #value="slotProps">
                  <div v-if="slotProps">
                    <span>
                      {{ slotProps.value.nome }}
                    </span>
                  </div>
                  <div v-else>
                    <span>{{ slotProps.placeholder }}</span>
                  </div>
                </template>

                <template #option="slotProps">
                  <div>
                    <span>
                      {{ slotProps.option.nome }}
                    </span>
                  </div>
                </template>
              </Dropdown>
              <div
                v-if="
                  submitted &&
                  v$.consignacao.statusConsignacaoDto.required.$invalid
                "
                class="p-error">
                O campo tipo é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="rubrica" class="required">Rubrica</label>
              <Dropdown
                id="rubrica"
                v-model="v$.consignacao.rubrica.$model"
                data-key="id"
                :disabled="desativarCampo()"
                :class="{
                  'p-invalid': submitted && v$.consignacao.rubrica.$invalid,
                }"
                :options="rubricas"
                placeholder="Selecione uma rubrica">
                <template #value="slotProps">
                  <div v-if="slotProps.value.rubrica">
                    <span>
                      {{ slotProps.value.rubrica }} |
                      {{ slotProps.value.nome }} |
                      {{ slotProps.value.atividade.nome }}
                    </span>
                  </div>
                  <div v-else>
                    <span>{{ slotProps.placeholder }}</span>
                  </div>
                </template>

                <template #option="slotProps">
                  <div>
                    <span>
                      {{ slotProps.option.rubrica }} |
                      {{ slotProps.option.nome }} |
                      {{ slotProps.option.atividade.nome }}
                    </span>
                  </div>
                </template>
              </Dropdown>
              <div
                v-if="submitted && v$.consignacao.rubrica.required.$invalid"
                class="p-error">
                O campo rubrica é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="numeroContrato" class="required">Nº Contrato</label>
              <InputText
                id="numeroContrato"
                v-model="v$.consignacao.numeroContrato.$model"
                :class="{
                  'p-invalid':
                    submitted && v$.consignacao.numeroContrato.$invalid,
                }"
                type="text"
                :disabled="desativarCampo()"
                placeholder="Digite o número contrato" />
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="valorParcela" class="required">Valor Parcela</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">R$</span>
                <InputNumber
                  id="valorParcela"
                  v-model="v$.consignacao.valorParcela.$model"
                  mode="decimal"
                  locale="pt-BR"
                  :minFractionDigits="2"
                  :maxFractionDigits="5"
                  :disabled="desativarCampo()"
                  :class="{
                    'p-invalid':
                      submitted && v$.consignacao.valorParcela.$invalid,
                  }"
                  placeholder="0,00" />
              </div>
              <div
                v-if="
                  submitted && v$.consignacao.valorParcela.required.$invalid
                "
                class="p-error">
                O campo valor parcela deve conter um número superior ou igual a
                1.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="anoReferencia">Ano</label>
              <InputMask
                id="anoReferencia"
                v-model="v$.consignacao.anoReferencia.$model"
                :disabled="desativarCampo()"
                mask="9999"
                :class="{
                  'p-invalid':
                    submitted && v$.consignacao.anoReferencia.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignacao.anoReferencia.required.$invalid
                "
                class="p-error">
                O campo ano é obrigatório
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="mesReferencia">Mês (1 até 12)</label>
              <InputNumber
                id="mesReferencia"
                v-model="v$.consignacao.mesReferencia.$model"
                :disabled="desativarCampo()"
                :min="1"
                :max="12"
                :class="{
                  'p-invalid':
                    submitted && v$.consignacao.mesReferencia.$invalid,
                }" />

              <div
                v-if="
                  submitted && v$.consignacao.mesReferencia.required.$invalid
                "
                class="p-error">
                O campo mês é obrigatório
              </div>
              <div
                v-if="
                  submitted &&
                  v$.consignacao.mesReferencia.numeroPermitido.$invalid
                "
                class="p-error">
                O campo mês deve ser entre 01 e 12
              </div>
            </div>
            <div v-if="exibirCampo()" class="inline-block col-6 md:col-6">
              <label for="prazoRestante" class="required">Prazo Restante</label>
              <InputNumber
                id="prazoRestante"
                v-model="v$.consignacao.prazoRestante.$model"
                :min="0"
                :max="999"
                :class="{
                  'p-invalid':
                    submitted && v$.consignacao.prazoRestante.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignacao.prazoRestante.required.$invalid
                "
                class="p-error">
                O campo prazo total é obrigatório.
              </div>
              <div
                v-if="
                  submitted && v$.consignacao.prazoRestante.maxValue.$invalid
                "
                class="p-error">
                O campo prazo restante não pode ser maior do que o prazo total
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="prazoTotal" class="required">Prazo Total</label>
              <InputNumber
                id="prazoTotal"
                v-model="v$.consignacao.prazoTotal.$model"
                :min="0"
                :max="999"
                :class="{
                  'p-invalid': submitted && v$.consignacao.prazoTotal.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignacao.prazoTotal.required.$invalid"
                class="p-error">
                O campo prazo total é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="carencia" class="required">Carência</label>
              <InputNumber
                id="carencia"
                v-model="v$.consignacao.carencia.$model"
                :disabled="desativarCampo()"
                :min="0"
                :max="6"
                placeholder="Informar a carência em meses (Entre 0 e 6)"
                :class="{
                  'p-invalid': submitted && v$.consignacao.carencia.$invalid,
                }" />

              <div
                v-if="submitted && v$.consignacao.carencia.required.$invalid"
                class="p-error">
                O campo carência é obrigatório
              </div>
            </div>

            <div class="inline-block col-6 md:col-6">
              <div v-show="statusCancelado">
                <label for="canceladoEm">Cancelado Em</label>
                <InputText
                  id="cancelado"
                  :value="cancelado | formatarData"
                  disabled />
              </div>
            </div>

            <div class="p-fluid formgrid grid col-12">
              <div class="field md:col-12">
                <label for="observacao" class="required">Observação</label>
                <Textarea
                  id="observacao"
                  v-model="v$.consignacao.observacoes.$model"
                  :class="{
                    'p-invalid':
                      submitted && v$.consignacao.observacoes.$invalid,
                  }"
                  :autoResize="true" />
                <div
                  v-if="
                    submitted && v$.consignacao.observacoes.required.$invalid
                  "
                  class="p-error">
                  O campo Observação é obrigatório
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { required, requiredIf, maxValue } from '@vuelidate/validators'
import Consignacao from '@/domain/Consignacao'
import ConsignacaoService from '@/service/ConsignacaoService.js'
import RubricaService from '@/service/RubricaService.js'
import UtilService from '@/service/UtilService'
import { servidorStore } from '@/stores/servidor'

const numeroPermitido = (value) =>
  validarCampoMesReferenciaNumeroPermitido(value)

function validarCampoMesReferenciaNumeroPermitido(value) {
  return value && value > 0 && value <= 12 ? true : false
}

export default {
  props: {
    visible: { type: Boolean, required: true },
    atualizacao: { type: Boolean, required: true },
    propsconsignacao: {
      type: Object,
      required: false,
      default: new Consignacao(),
    },
  },

  setup() {
    const storeServidor = servidorStore()
    return { storeServidor, v$: UseVuelidate() }
  },

  data() {
    return {
      cancelado: null,
      statusCancelado: false,

      consignacao: new Consignacao(),
      rubricas: [
        {
          nome: '',
        },
      ],
      saving: false,
      confirmDialog: false,
      submitted: false,
      listaStatusConsignacao: [
        {
          codigo: null,
          descricao: '',
        },
      ],
    }
  },
  watch: {
    'v$.consignacao.statusConsignacaoDto.$model': function () {
      this.exibirCanceladoEm()
    },
  },

  validations() {
    return {
      consignacao: {
        rubrica: {
          required,
        },
        numeroContrato: { required: false },
        valorParcela: {
          required,
        },
        prazoRestante: {
          required: requiredIf(this.atualizacao),
          maxValue: maxValue(this.consignacao?.prazoTotal),
        },
        prazoTotal: {
          required,
        },
        carencia: {
          required,
        },
        anoReferencia: {
          required,
        },
        mesReferencia: {
          required,
          numeroPermitido,
        },
        statusConsignacaoDto: {
          required,
        },
        observacoes: { required },
      },
    }
  },

  created() {
    this.consignacaoService = new ConsignacaoService(this.$http)
    this.rubricaService = new RubricaService(this.$http)
    this.utilService = new UtilService(this.$http)
  },

  mounted() {
    this.carregarRubricas()
    this.carregarListaStatusConsignacao()
  },

  methods: {
    exibirHeader() {
      return this.atualizacao ? 'Editar Consignação' : 'Nova Consignação'
    },

    desativarCampo() {
      return false
    },

    exibirCampo() {
      return this.atualizacao ? true : false
    },

    fechar() {
      this.$emit('update:visible', false)
    },

    exibirCanceladoEm() {
      if (
        this.consignacao.statusConsignacaoDto.id == 4 ||
        this.consignacao.statusConsignacaoDto.id == 11 ||
        this.consignacao.statusConsignacaoDto.id == 3 ||
        this.consignacao.statusConsignacaoDto.id == 13
      ) {
        this.statusCancelado = true
        if (!this.consignacao.canceladoEm) {
          var date = new Date()
          this.cancelado = date.toISOString()
        } else {
          this.cancelado = this.consignacao.canceladoEm
        }
      } else {
        this.statusCancelado = false
        this.cancelado = null
      }
    },

    onShow() {
      if (this.propsconsignacao != null) {
        this.consignacao = JSON.parse(JSON.stringify(this.propsconsignacao))
      }
    },

    onHide() {},
    carregarRubricas() {
      this.rubricaService.getListaRubricas().then(
        (res) => {
          this.rubricas = res
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    carregarListaStatusConsignacao() {
      this.consignacaoService.carregarListaStatusConsignacao().then((res) => {
        this.listaStatusConsignacao = res
      })
    },

    validate() {
      this.submitted = true
      this.v$.consignacao.$touch()

      return this.v$.consignacao.$invalid ? '' : this.salvar()
    },

    salvar() {
      this.saving = true

      if (this.atualizacao) {
        this.consignacao.canceladoEm = this.cancelado
        this.consignacaoService
          .atualizar(this.consignacao)
          .then((res) => {
            this.$emit('onUpdate', res.data)
            this.$toast.add({
              severity: 'success',
              summary: 'Consignação atualizada com sucesso.',
              life: 10000,
            })
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
          .finally(() => {
            this.saving = false
            this.fechar()
          })
      } else {
        this.consignacaoService
          .saveConsignacao(
            this.consignacao,
            this.consignacao.rubrica.consignataria.id,
          )
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Consignação cadastrada com sucesso.',
              life: 10000,
            })
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
          .finally(() => {
            this.saving = false
            this.fechar()
          })
      }
    },
  },
}
</script>
<style>
.required:after {
  content: ' *';
  color: red;
}

.p-card {
  box-shadow: none;
}

.p-dialog .p-card .p-card-body {
  padding: 0rem !important;
}

.break-line {
  white-space: break-spaces;
}
</style>
