<template>
  <Card>
    <template #content>
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Voltar"
            icon="pi pi-chevron-left"
            class="mt-2 mr-2 p-button-sm p-button-secondary"
            @click="$router.back()" />

          <Button
            v-if="$auth.hasRoleGestaoAdmin()"
            title="Editar Consignação"
            icon="pi pi-pencil"
            class="mt-2 mr-2 p-button-sm p-button-warning"
            @click="showEditConsignacaoFormDialog()" />

          <Button
            title="Proposta"
            icon="pi pi-file"
            class="mt-2 mr-2 p-button-sm"
            @click="exibirDetalheProposta()" />

          <Button
            v-if="$auth.hasRoleGestaoAdmin()"
            title="Gerar Comprovante de Autorização"
            class="mt-2 mr-2 p-button-sm"
            @click="gerarRelatorioAutorizacao()">
            <span v-if="!loadingPdf" class="pi pi-print"></span>
            <span v-if="loadingPdf" class="pi pi-spin pi-spinner"></span>
            <span v-if="!loadingPdf"></span>
            <span v-if="loadingPdf">Aguarde</span>
          </Button>
        </template>
      </Toolbar>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="rubrica">Rubrica</label>
          <InputText id="rubrica" v-model="dadosRubrica" disabled />
        </div>
        <div class="field col-6 md:col-6">
          <label for="numeroContrato">Nº Contrato</label>
          <InputText
            id="numeroContrato"
            v-model="consignacao.numeroContrato"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="valor">Valor da Parcela</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <InputText id="valor" v-model="consignacao.valorParcela" disabled />
          </div>
        </div>
        <div class="field col-6 md:col-6">
          <label for="situacao">Situação</label>
          <InputText
            id="situacao"
            v-model="consignacao.statusConsignacaoDto.nome"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="prazoRestante">Prazo Restante</label>
          <InputText
            id="prazoRestante"
            v-model="consignacao.prazoRestante"
            disabled />
        </div>
        <div class="field col-6 md:col-6">
          <label for="prazoTotal">Prazo Total</label>
          <InputText
            id="prazoTotal"
            v-model="consignacao.prazoTotal"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="ano">Ano</label>
          <InputText id="ano" v-model="consignacao.anoReferencia" disabled />
        </div>
        <div class="field col-6 md:col-6">
          <label for="mes">Mês</label>
          <InputText id="mes" v-model="consignacao.mesReferencia" disabled />
        </div>
        <div v-if="prestacao" class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="valor">Valor da Prestação</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <InputText id="valor" v-model="prestacao.valor" disabled />
            </div>
          </div>
          <div class="field col-3 md:col-3">
            <label for="anoPrestacao">Ano da Prestação</label>
            <InputText
              id="anoPrestacao"
              v-model="prestacao.anoReferencia"
              disabled />
          </div>
          <div class="field col-3 md:col-3">
            <label for="mesPrestacao">Mês da Prestação</label>
            <InputText
              id="mesPrestacao"
              v-model="prestacao.mesReferencia"
              disabled />
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="cadastradoPor">Cadastrado Por</label>
          <InputText
            v-if="
              consignacao.cadastradoPor !== null &&
              consignacao.propostaConsignacao === null
            "
            id="cadastradoPor"
            v-model="consignacao.cadastradoPor.nome"
            disabled />
          <InputText
            v-if="
              consignacao.propostaConsignacao !== null &&
              consignacao.propostaConsignacao.cadastradoPor !== null
            "
            id="cadastradoPor"
            v-model="consignacao.propostaConsignacao.cadastradoPor.nome"
            disabled />
          <InputText v-else id="cadastradoPor" disabled />
        </div>
        <div class="field col-6 md:col-6">
          <label for="cadastradoEm">Cadastrado Em</label>
          <InputText
            id="cadastradoEm"
            :value="consignacao.auditMetadata.cadastradoEm | formatarData"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="observacao">Observação</label>
          <Textarea
            id="observacao"
            v-model="consignacao.observacoes"
            :autoResize="true"
            disabled />
        </div>
      </div>

      <ConsignacaoFormDialog
        :visible.sync="showConsignacaoFormDialog"
        :propsconsignacao="consignacao"
        :atualizacao="atualizacao"
        @onUpdate="onUpdateConsignacao" />

      <DataTable
        class="p-datatable-sm"
        :paginator="true"
        :rows="5"
        stripedRows
        :loading="loading"
        :value="historicoConsignacao"
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="rowsPerPageOptions"
        currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
        responsiveLayout="scroll">
        <template #header>Histórico Consignação</template>
        <template #empty> Nenhum histórico encontrado. </template>
        <template #loading> Carregando. Por favor aguarde. </template>
        <Column header="Cadastrado Em">
          <template #body="{ data }">
            {{ data.cadastradoEm | formatarData }}
          </template>
        </Column>
        <Column header="Cadastrado Por">
          <template #body="{ data }">
            {{ data.cadastradoPor }}
          </template>
        </Column>
        <Column
          :sortable="true"
          field="tipo"
          header="Situação"
          :styles="{ 'min-width': '12rem' }">
          <template #body="{ data }">
            <Chip
              v-if="data.tipo === 'PENDENTE'"
              label="Pendente"
              class="mr-2 mb-2 custom-chip pendente" />
            <Chip
              v-if="data.tipo === 'ATIVA'"
              label="Ativa"
              class="mr-2 mb-2 custom-chip ativa" />
            <Chip
              v-if="data.tipo === 'QUITADA'"
              label="Quitada"
              class="mr-2 mb-2 custom-chip quitada" />
            <Chip
              v-if="data.tipo === 'CANCELADA'"
              label="Cancelada"
              class="mr-2 mb-2 custom-chip cancelada" />
            <Chip
              v-if="data.tipo === 'SUSPENSA'"
              label="Suspensa"
              class="mr-2 mb-2 custom-chip suspensa" />
            <Chip
              v-if="data.tipo === 'AGUARDANDO_SUPERVIDOR'"
              label="Aguardando Supervisor"
              class="mr-2 mb-2 custom-chip aguardando-supervisor" />
            <Chip
              v-if="data.tipo === 'NAO_APROVADA_SUPERVISOR'"
              label="Não Aprovada Supervisor"
              class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
            <Chip
              v-if="data.tipo === 'BAIXADA'"
              label="Baixada"
              class="mr-2 mb-2 custom-chip baixada" />
            <Chip
              v-if="data.tipo === 'NAO_DESCONTADA'"
              label="Não descontada"
              class="mr-2 mb-2 custom-chip não-descontada" />
            <Chip
              v-if="data.tipo === 'SOLICITADO_CANCELAMENTO'"
              label="Cancelada"
              class="mr-2 mb-2 custom-chip cancelada" />
            <Chip
              v-if="data.tipo === 'SOLICITADA_SUSPENSAO'"
              label="Suspensa"
              class="mr-2 mb-2 custom-chip suspensa" />
            <Chip
              v-if="data.tipo === 'MOVIMENTO_PARCIAL'"
              label="Mov. Parcial"
              class="mr-2 mb-2 custom-chip foda-da-margem" />
            <Chip
              v-if="data.tipo === 'FORA_MARGEM'"
              label="Fora da margem"
              class="mr-2 mb-2 custom-chip foda-da-margem" />
            <Chip
              v-if="data.tipo === 'DESCONTO_A_MENOR'"
              label="Desc. a Menor"
              class="mr-2 mb-2 custom-chip foda-da-margem" />
          </template>
        </Column>
        <Column header="Motivo">
          <template #body="{ data }">
            {{ data.motivo ? data.motivo : '-' }}
          </template>
        </Column>
        <Column header="Observacao">
          <template #body="{ data }">
            {{ data.observacao ? data.observacao : '-' }}
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import ConsignacaoFormDialog from './ConsignacaoFormDialog.vue'
import { bus } from '@/main'
import ConsignacaoDetalhe from '@/components/shared/consignacao/consignacaoDetalhe.vue'

export default {
  components: {
    ConsignacaoFormDialog,
  },

  extends: ConsignacaoDetalhe,

  data() {
    return {
      atualizacao: false,
      showConsignacaoFormDialog: false,
      saving: false,
      loadingPdf: false,
      submitted: false,
      rowsPerPageOptions: [5, 10, 25],
      relatorioAutorizacao: {
        nome: 'autorizacaoConsignacao',
        tipo: 'AUTORIZACAO',
        contentType: 'PDF',
        parametros: new Map(),
      },
    }
  },

  methods: {
    showEditConsignacaoFormDialog() {
      this.atualizacao = true
      this.showConsignacaoFormDialog = true
    },

    gerarRelatorioAutorizacao() {
      this.relatorioAutorizacao.parametros.set(
        'consignacaoId',
        this.consignacao.id,
      )
      this.loadingPdf = true
      this.relatorioService
        .gerarRelatorioViaJasper(this.relatorioAutorizacao)
        .then(
          (res) => {
            this.downloadPdf(res, this.relatorioAutorizacao.nome)
            this.loadingPdf = false
            this.relatorioAutorizacao.parametros = new Map()
          },
          (err) => {
            this.relatorioAutorizacao.parametros = new Map()
            this.exibeToast('error', err.response.data.message)
            this.loadingPdf = false
          },
        )
    },

    exibirDetalheProposta() {
      this.$router.push({
        name: 'proposta-detalhe',
        params: { idproposta: this.consignacao.propostaConsignacao.id },
      })
    },

    onUpdateConsignacao(event) {
      this.consignacao = event
      bus.$emit('carregarDadosServidor')
    },

    salvar() {
      this.saving = true
      this.servidorService
        .atualizarServidor(this.idMatricula, this.servidor)
        .then(() => {
          this.fecharEditarServidor()
          this.carregarDadosServidor()
          this.$toast.add({
            severity: 'success',
            summary: 'Servidor atualizado com sucesso.',
            life: 10000,
          })
        })
        .catch((err) => {
          console.log(err)
          this.$toast.add({
            severity: 'error',
            summary: 'Ocorreu um erro ao atualizar servidor.',
            life: 10000,
          })
          this.saving = false
        })
    },
  },
}
</script>
